import React from "react"
import { graphql } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import { GatsbyImage, getImage } from "gatsby-plugin-image"

// Компоненты
import Menu from "../components/menu.js"
import Seo from "../components/seo.js"
import Footer from "../components/footer.js"

// Картинки
import rating from "../images/rating.svg"
import articles from "../images/articles.svg"
import reviews from "../images/reviews.svg"

export default function Home({data}) {
	return (
		<>	
			<Seo />
			<Menu />
			<div className="hero">
				<div className="text-center max-w-[540px] md:max-w-[720px] lg:max-w-7xl">
					<h1 className="text-4xl md:text-6xl font-normal text-white mb-7">Найди идеальный матрас</h1>
				    <p className="text-white md:w-2/3 m-auto">Актуальные обзоры и рейтинги популярных моделей матрасов и их производителей</p>
				    <a href="/reiting-matrasov/"><button className="button button_primary mt-14 ml-auto mr-auto">Рейтинг матрасов {(new Date().getFullYear())}</button></a>
			    </div>
		    </div>
		    <div className="about-us text-center py-6 md:py-16 max-w-[540px] md:max-w-[720px] lg:max-w-7xl mx-auto px-[15px]">
		    	<h2 className="text-3xl mb-14 font-semibold text-decoration">Как мы помогаем выбрать матрас</h2>
		    	<div className="flex flex-wrap cards">
		    		<div className="cards__card card-about px-4 cursor-pointer w-full md:w-1/3">
			    		<a href="/site-map/#category">
			    			<img src={rating} alt="Рейтинги" width="120" height="120" className="m-auto mb-5" loading="lazy" />
			    			<div>
				    			<p className="text-2xl font-medium mb-3">Рейтинги</p>
				    			<p className="text-zinc-600">Рейтинги основаны на отзывах покупателей и реальных тестах нашими экспертами.</p>
			    			</div>
		    			</a>
		    		</div>
		    		<div className="cards__card card-about px-4 cursor-pointer w-full md:w-1/3">
			    		<a href="/site-map/#review">
			    			<img src={reviews} alt="Обзоры" width="120" height="120" className="m-auto mb-5" loading="lazy" />
			    			<div>
				    			<p className="text-2xl font-medium mb-3">Обзоры</p>
				    			<p className="text-zinc-600">Только честные обзоры, составленные нашей командой в процессе тестирования матрасов.</p>
			    			</div>
		    			</a>
		    		</div>
		    		<div className="cards__card card-about px-4 cursor-pointer w-full md:w-1/3">
			    		<a href="/site-map/#posts">
			    			<img src={articles} alt="Статьи" width="120" height="120" className="m-auto mb-5" loading="lazy" />
			    			<div>
				    			<p className="text-2xl font-medium mb-3">Статьи</p>
				    			<p className="text-zinc-600">Полезные статьи о сне и товарах, сопутствующих здоровому и крепкому сну.</p>
			    			</div>
		    			</a>
		    		</div>
		    	</div>
		    </div>
		    <div className="winner text-center py-6 md:py-16 max-w-[540px] md:max-w-[720px] lg:max-w-7xl mx-auto px-[15px]">
		    	<h2 className="text-3xl mb-14 font-semibold text-decoration">Победители {(new Date().getFullYear())} года</h2>
		    	<div className="flex flex-wrap cards">
		    		{data.allContentfulMattress.edges.map(({ node }, i) => (
			    		<div key={node.id} className="cards__card px-4 w-full md:w-1/3">
				    		<a href={node.affilateLink ? node.affilateLink : node.linkPrice} target="_blank" rel="noopener nofollow noreferrer sponsored">
				    			<span className={i === 0 ? 'one' : '' || i === 1 ? 'two' : '' || i === 2 ? 'three' : ''}>{1+(i++)}</span>
				    			<div className="logo-block m-auto mb-5">
				    				<GatsbyImage image={getImage(node.brand.image)} alt={node.brand.name} />
				    			</div>
				    			<div className="image-mattress">
				    				<GatsbyImage image={getImage(node.image)} alt={node.name} />
				    			</div>
				    			<p className="text-xl font-semibold mb-3">{node.name}</p>
			    			</a>
			    		</div>
		    		))}
		    	</div>
		    </div>
		    <div className="categories text-center py-6 md:py-16 max-w-[540px] md:max-w-[720px] lg:max-w-7xl mx-auto px-[15px]">
		    	<h2 className="text-3xl mb-14 font-semibold text-decoration">Популярные подборки матрасов</h2>
		    	<div className="switchable switchable-img-right justify-between flex flex-wrap items-center md:px-4">
	                <div className="w-full md:w-1/2 md:px-4 order-1 md:order-none mt-[30px] md:mt-0 text-center md:text-left">
	                    <h3 className="mb-5 text-2xl font-medium">Недорогие матрасы</h3>
	                    <p className="max-w-md">Цена не всегда показатель высочайшего качества. Составленный нами рейтинг поможет Вам в поисках достойной модели среди недорогих матрасов.</p>
	                    <a href="/luchshie-nedorogie-matrasy/"><button className="button button_primary button_s mt-5 mx-auto md:mx-0">Читать</button></a>
	                </div>
	                <div className="w-full md:w-1/2 md:px-4">
	                	<div className="img-categories">
		                	<div className="w-1/3">
		                		<StaticImage src="../images/pexels-photo-90317.jpeg" alt="Недорогие матрасы" placeholder="none" quality={100} />
	                		</div>
	        			</div>
	                </div>
	        	</div>
	        	<div className="switchable switchable-img-left justify-between flex flex-wrap items-center md:px-4 mt-24">
		        	<div className="w-full md:w-1/2 md:px-4">
		        		<div className="img-categories">
		                	<div className="w-1/3">
		        				<StaticImage src="../images/pexels-photo-1139784.jpeg" alt="Детские матрасы" placeholder="none" quality={100} />
		        			</div>
	        			</div>
		        	</div>	
	                <div className="w-full md:w-1/2 md:px-4 mt-[30px] md:mt-0 text-center md:text-left">
	                    <h3 className="mb-5 text-2xl font-medium">Детские матрасы</h3>
	                    <p className="max-w-md">Здоровье ребенка - главная забота каждого родителя, а сон на правильно подобранном матрасе поможет в формировании активно развивающегося детского организма.</p>
	                    <a href="/luchshie-detskie-matrasy/"><button className="button button_primary button_s mt-5 mx-auto md:mx-0">Читать</button></a>
	                </div>
	        	</div>
	        	<div className="switchable switchable-img-right justify-between flex flex-wrap items-center md:px-4 mt-24">
	                <div className="w-full md:w-1/2 md:px-4 order-1 md:order-none mt-[30px] md:mt-0 text-center md:text-left">
	                    <h3 className="mb-5 text-2xl font-medium">Матрасы для дивана</h3>
	                    <p className="max-w-md">Сон на диване не должен стать проблемой, вызывающей боли в спине. Наш рейтинг поможет в поисках подходящей, именно Вам, модели.</p>
	                    <a href="/luchshie-tonkie-matrasy/"><button className="button button_primary button_s mt-5 mx-auto md:mx-0">Читать</button></a>
	                </div>
	                <div className="w-full md:w-1/2 md:px-4">
	                	<div className="img-categories">
		                	<div className="w-1/3">
	                			<StaticImage src="../images/pexels-photo-298842.jpeg" alt="Матрасы для дивана" placeholder="none" quality={100} />
	                		</div>
	        			</div>
	                </div>
	        	</div>
	        	<div className="switchable switchable-img-left justify-between flex flex-wrap items-center md:px-4 mt-24">
	        		<div className="w-full md:w-1/2 md:px-4">
	        			<div className="img-categories">
		                	<div className="w-1/3">
	        					<StaticImage src="../images/aw-creative-nclN_J0UtJ8-unsplash-scaled.jpg" alt="Двуспальные матрасы" placeholder="none" quality={100} />
	        				</div>
	        			</div>
	        		</div>
	                <div className="w-full md:w-1/2 md:px-4 mt-[30px] md:mt-0 text-center md:text-left">
	                    <h3 className="mb-5 text-2xl font-medium">Двуспальные матрасы</h3>
	                    <p className="max-w-md">Мы выбираем матрасы подходящие нашим требованиям. Данный рейтинг поможет в поиске модели, которая по характеристикам подойдет и Вам и вашей второй половине.</p>
	                    <a href="/luchshie-dvuspalnye-matrasy/"><button className="button button_primary button_s mt-5 mx-auto md:mx-0">Читать</button></a>
	                </div>
	        	</div>
		    </div>
		    <div className="brands text-center py-6 md:py-16 max-w-[540px] md:max-w-[720px] lg:max-w-7xl mx-auto px-[15px]">
		    	<h2 className="text-3xl mb-14 font-semibold text-decoration">Популярные бренды</h2>
		    	<div className="cards grid grid-cols-2 justify-center md:grid-cols-3 lg:grid-cols-4 gap-4 md:gap-8">
		    		{data.allContentfulBrand.edges.map(({ node }, i) => (
		    			<div key={node.id} className="cards__card card-brand cursor-pointer">
				    		<a href={'/'+node.linkBrandPage+'/'}>
					    		<div className="image-logo">
					    			<GatsbyImage image={getImage(node.image)} alt={node.name} />
				    			</div>
				    			<p className="text-1xl font-medium">{node.name}</p>
			    			</a>
			    		</div>
		    		))}
		    	</div>
		    </div>
		    <div className="overflow-hidden">
			    <div className="about-us-home py-16">
	                <div className="container max-w-[540px] md:max-w-[720px] lg:max-w-7xl mx-auto px-[15px]">
	               		<div>
	                    	<h2>SleepAdvisor - помощник в поиске здорового сна</h2>
	                        <p>Современный рынок матрасов предлагает потребителю огромный ассортимент, но не каждая модель может подойти именно Вам. При выборе надо учитывать не только конструкцию, но и особенности человека, такие как вес, положение тела во сне и индивидуальность организма.&nbsp; Разобраться в большом количестве свойств и характеристик матрасов не простая задача, а команда SleepAdvisor поможет с выбором.</p>
	                        <a href="/about-us/"><button className="button button_ghost button_m mt-12">Подробнее о нас</button></a>
	                    </div>
	                    <StaticImage src="../images/28546.png" alt="О нас" placeholder="none" quality="100" />
	                </div>
	            </div>
            </div>
            <div className="blog py-6 md:py-16 max-w-[540px] md:max-w-[720px] lg:max-w-7xl mx-auto px-[15px]">
                <div className="grid gap-2 lg:gap-6 grid-cols-3 lg:grid-cols-4">
                    <div className="flex flex-col justify-center col-span-4 lg:col-span-1 text-center lg:text-left md:px-[70px] lg:px-0 mb-[30px] lg:mb-0">
                        <h3 className="text-center lg:text-left">Интересные статьи</h3>
                        <p>Читайте полезные статьи на нашем сайте о матрасах, способствующих здоровому и крепкому сну</p>
                        <a className="just-link mt-5" href="/blog/">Посмотреть все статьи →</a>
                    </div>
                    {data.allContentfulBlog.edges.slice(0 , 3).map(({ node }) => (
	                    <div key={node.id} className="background-image-holder col-span-4 md:col-span-1">
	                        <a className="block" href={'/'+node.url+'/'}>
	                        	<GatsbyImage image={getImage(node.image)} alt={node.titlePage} />
	                            <div className="article__title">
	                                {/*<span className="read-time">
	                                	<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 20 20"><path fill="#fff" fill-rule="evenodd" d="M9.99 0C4.47 0 0 4.48 0 10s4.47 10 9.99 10C15.52 20 20 15.52 20 10S15.52 0 9.99 0zM10 18c-4.42 0-8-3.58-8-8s3.58-8 8-8 8 3.58 8 8-3.58 8-8 8zm.5-13H9v6l5.25 3.15.75-1.23-4.5-2.67V5z"></path></svg>
	                                	11 мин
	                                </span>*/}
	                                <span>{node.createdAt}</span>
	                                <h5 className="text-lg font-semibold">{node.titlePage}</h5>
	                            </div>
	                        </a>
	                    </div>
                    ))}
                </div>
            </div>
            <Footer />
	    </>
    )
}

export const query = graphql`
  	query {
    	allContentfulBlog(sort: { fields: [createdAt], order: DESC }) {
      		edges {
	        	node {
	          		id
	          		url
	          		titlePage
	          		updatedAt
	          		createdAt(formatString: "DD MMMM YYYY", locale: "ru")
	          		image {
	          			gatsbyImageData(
						    layout: FIXED
						    quality: 100
						)
			          	file {
			            	url
			            	details {
			              		image {
				                	height
				                	width
			              		}
			            	}
			          	}
			        }
	        	}
	      	}
    	}
    	allContentfulMattress(filter: {name: {in: ["Fitness Leader", "Ocean Wave", "Sanders"]}}) {
		    edges {
		      	node {
		        	id
		        	name
		        	linkPrice
		        	affilateLink
		        	image {
		          		gatsbyImageData(
							layout: FIXED
							quality: 100
						)
		        	}
		        	brand {
		        		name
		          		image {
		            		gatsbyImageData(
							    layout: FIXED
							    quality: 100
							)
		          		}
		        	}
		      }
		    }
		}
		allContentfulBrand(sort: {fields: positionOnHomepage, order: ASC}, filter: {name: {in: ["Аскона", "Орматек", "Dreamline", "Промтекс Ориент", "Serta", "Dimax", "Lonax", "Mediflex"]}}) {
	    	edges {
	      		node {
	        		id
	        		name
	        		positionOnHomepage
	        		linkBrandPage
	        		image {
	          			gatsbyImageData (
						    layout: FIXED
						    quality: 100
						)
	        		}
	      		}
	    	}
	  	}
  	}
`